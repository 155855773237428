<template>
  <b-card>
    <b-card-body>
      <div class="row">
        <div class="col-sm-8 order-2 order-sm-1">
          <h1 class="mb-3">
            {{ product.title }}
          </h1>
          <b-card-text v-html="product.description">
            {{ product.description }}
          </b-card-text>
        </div>
        <div class="col-sm-4 order-1 order-sm-2">
          <b-card
            class="ecommerce-card sticky-top"
            nobody
            border-variant="primary"
            bg-variant="light"
            align="center"
            style="top: 150px;"
          >
            <!-- Product Details -->
            <b-card-body>
              <div class="item-wrapper">
                <img :src="product.thumbnail" alt="course image" class="course-image">
                <div>
                  <h6
                    class="item-price"
                    style="font-size:20px;"
                  >
                    {{ (product.price * 0.01).toFixed(2) }} zł
                  </h6>
                  <h6
                    v-if="product.sale_price"
                    class="item-price-sale"
                    style="font-size:16px;text-decoration: line-through"
                  >
                    {{ (product.sale_price * 0.01).toFixed(2) }} zł
                  </h6>
                </div>
              </div>

              <!-- Action Buttons -->
              <div class="item-options text-center">
                <b-button
                  variant="primary"
                  class="btn-cart move-cart"
                  @click="handleCartActionClick(product)"
                >
                  <feather-icon
                    icon="ShoppingCartIcon"
                    class="mr-50"
                  />
                  <span>Dodaj do koszyka</span>
                </b-button>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BCardBody,
} from 'bootstrap-vue'
import { useEcommerceUi } from '@/views/shop/useEcommerce'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BButton,
  },
  data() {
    return {
      product: {},
    }
  },
  created() {
    this.fetchCourse(this.$route.params.slug)
  },
  methods: {
    fetchCourse(slug) {
      this.$http.get(`https://${this.$store.state.api_domain}/api/courses/${slug}`).then(response => {
        this.product = response.data
      })
    },
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    return {
      toggleProductInWishlist,
      handleCartActionClick,
    }
  },
}
</script>

<style>
img.course-image {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}
</style>
